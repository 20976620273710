import React from 'react'
import Layout from 'components/Layout'
import SEO from 'components/seo'
import { navigate } from 'gatsby'
import { WrapperSystemError } from './styles'
import error from './media/SystemError.png'
import Button from 'components/Button'

function SystemErrorPage() {
  return (
    <>
      <Layout>
        <SEO title='system-error' />
        <WrapperSystemError>
          <img 
            src={ error } 
            className='system-image' 
            alt='error'
          />
          <h1>SYSTEM ERROR</h1>
          <p>Maybe you can refresh the page</p>
          <Button type='warning' onClick={ () => navigate('/') }>
            GO BACK TO HOME
          </Button>
        </WrapperSystemError>
      </Layout>
    </>
  )
}

export default SystemErrorPage
