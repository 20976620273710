import styled from "styled-components";
import { lightGold, darkBlueGrey } from "../../components/baseColor";

export const WrapperSystemError = styled.div`
  padding-top: 50px;
  padding-bottom: 25px;
  text-align: center;
  align-items: center;
  h1 {
    font-size: 24px;
    padding-top: 25px;
    color: ${darkBlueGrey};
  }
  .system-image {
    width: 25%;
    margin-left: 50px;
  }
  button {
    font-size: 14px;
    width: 200px;
    height: 46px;
  }

  @media (max-width: 414px) {
    h1 {
      font-size: 18px;
      color: ${darkBlueGrey};
      font-style: normal;
      font-weight: 800;
    }
    p {
      font-size: 14px;
      letter-spacing: normal;
      color: ${darkBlueGrey};
    }
    button {
      font-size: 14px;
      font-weight: 800;
      background-color: ${lightGold};
      margin-top: 15px;
      width: 250px;
      height: 40px;
    }

    .system-image {
      width: 55%;
      margin-right: 20px;
    }
  }
`;
